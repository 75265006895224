<template>
  <Dialog
    scrollable
    :common-dialog="deliveryDialog"
    :dialog-width="dialogWidth"
  >
    <template v-slot:title>
      <v-layout v-if="deliveryDialog">
        <v-flex md6 class="d-flex">
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            Delivery #
            {{ deliveryDetail.barcode }}
          </h1>
          <Priority
            v-if="deliveryDetail.priority"
            :priority="deliveryDetail.priority"
          ></Priority>

          <CustomStatus
            :status="deliveryDetail.status"
            endpoint="delivery/status"
          ></CustomStatus>
        </v-flex>

        <v-flex md6 class="d-flex justify-end">
          <v-btn
            class="ml-2 custom-grey-border custom-bold-button"
            v-on:click="$emit('close', true)"
          >
            Close
          </v-btn>
        </v-flex>
      </v-layout>
    </template>

    <template v-slot:body>
      <v-container>
        <v-row>
          <v-col md="5" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <div v-else>
                    <v-chip
                      class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                      :color="deliveryDetail?.status_relation?.color"
                      label
                      :class="
                        deliveryDetail?.status_relation?.text == 'En-Route'
                          ? ''
                          : 'text-white'
                      "
                    >
                      {{ deliveryDetail?.status_relation?.text }}
                    </v-chip>

                    <v-chip
                      :class="
                        deliveryDetail?.type == 'transfer' ? '' : 'text-white'
                      "
                      class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                      :color="getColor()"
                      label
                    >
                      <v-icon> {{ getIcon() }}</v-icon>
                      {{ deliveryDetail.type }}
                    </v-chip>
                  </div>
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 2"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table width="100%">
                      <tr>
                        <td
                          valign="top"
                          colspan="2"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          <p
                            class="m-0"
                            v-if="deliveryDetail?.type != 'transfer'"
                          >
                            <span class="d-block">
                              <v-chip
                                class="text-white text-capitalize"
                                label
                                :color="
                                  deliveryDetail?.customer_type ==
                                  'customer-list'
                                    ? 'blue'
                                    : 'red'
                                "
                                small
                              >
                                <template
                                  v-if="
                                    deliveryDetail?.customer_type ==
                                    'customer-list'
                                  "
                                >
                                  Customer
                                </template>
                                <template v-else> Supplier </template>
                              </v-chip>
                            </span>
                          </p>
                          <p
                            class="m-0"
                            v-if="deliveryDetail?.type == 'transfer'"
                          >
                            <span class="d-block">
                              <v-chip
                                class="text-white text-capitalize"
                                label
                                :color="
                                  deliveryDetail?.customer_type ==
                                  'customer-list'
                                    ? 'blue'
                                    : 'red'
                                "
                                small
                              >
                                <template
                                  v-if="
                                    deliveryDetail?.customer_type ==
                                    'customer-list'
                                  "
                                >
                                  Customer
                                </template>
                                <template v-else> Supplier </template>
                              </v-chip>
                              <v-icon>mdi-arrow-right</v-icon>
                              <v-chip
                                class="text-white text-capitalize"
                                label
                                :color="
                                  deliveryDetail?.to_customer_type ==
                                  'customer-list'
                                    ? 'blue'
                                    : 'red'
                                "
                                small
                              >
                                <template
                                  v-if="
                                    deliveryDetail?.to_customer_type ==
                                    'customer-list'
                                  "
                                >
                                  Customer
                                </template>
                                <template v-else> Supplier </template>
                              </v-chip>
                            </span>
                          </p>
                        </td>
                      </tr>
                    </table>
                    <table width="100%">
                      <tr>
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          <template
                            v-if="
                              deliveryDetail?.customer_type == 'customer-list'
                            "
                          >
                            <template v-if="deliveryDetail?.type == 'transfer'"
                              >From</template
                            >
                            Customer
                          </template>
                          <template v-else>
                            <template v-if="deliveryDetail?.type == 'transfer'"
                              >From</template
                            >
                            Supplier
                          </template>
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500 d-flex"
                        >
                          <span style="color: #24326d !important">
                            <v-chip
                              v-on:click="toRoute()"
                              small
                              color="blue darken-4"
                              class="cursor-pointer"
                              outlined
                              label
                            >
                              {{
                                deliveryDetail?.customer_relation?.display_name
                              }}
                            </v-chip>
                          </span>
                        </td>
                      </tr>
                      <tr v-if="deliveryDetail?.type == 'transfer'">
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          <template
                            v-if="
                              deliveryDetail?.to_customer_type ==
                              'customer-list'
                            "
                          >
                            To Customer
                          </template>
                          <template v-else>To Supplier </template>
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500 d-flex"
                        >
                          <span style="color: #24326d !important">
                            <v-chip
                              v-on:click="toRoute()"
                              small
                              color="blue darken-4"
                              class="cursor-pointer"
                              outlined
                              label
                            >
                              {{
                                deliveryDetail?.to_customer_relation
                                  ?.display_name
                              }}
                            </v-chip>
                          </span>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          deliveryDetail?.rental_id ||
                          deliveryDetail?.sale_id ||
                          deliveryDetail?.is_visit
                        "
                      >
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Related #
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <v-tooltip
                            bottom
                            content-class="custom-bottom-tooltip"
                            v-if="
                              deliveryDetail?.is_visit &&
                              deliveryDetail?.visit_id > 0
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                color="red"
                                outlined
                                text-color=""
                                class="custom-barcode p-3"
                                v-on:click.stop.prevent="
                                  $router.push(
                                    getDefaultRoute(`visit.detail`, {
                                      params: { id: deliveryDetail?.visit_id },
                                    })
                                  )
                                "
                              >
                                {{ deliveryDetail?.visit_barcode }}
                              </v-chip>
                            </template>
                            <span>Converted from visit</span>
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            content-class="custom-bottom-tooltip"
                            v-if="
                              deliveryDetail?.is_sell &&
                              deliveryDetail?.sale_id > 0
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                color="blue"
                                outlined
                                text-color=""
                                class="custom-barcode p-3"
                                v-on:click.stop.prevent="
                                  $router.push(
                                    getDefaultRoute(`sell.detail`, {
                                      params: { id: deliveryDetail?.sale_id },
                                    })
                                  )
                                "
                              >
                                {{ deliveryDetail?.sale_barcode }}
                              </v-chip>
                            </template>
                            <span>Converted from sell</span>
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            content-class="custom-bottom-tooltip"
                            v-if="
                              deliveryDetail?.is_rental &&
                              deliveryDetail?.rental_id > 0
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                color="orange"
                                outlined
                                text-color=""
                                class="custom-barcode p-3"
                                v-on:click.stop.prevent="
                                  $router.push(
                                    getDefaultRoute(`rental.detail`, {
                                      params: { id: deliveryDetail?.rental_id },
                                    })
                                  )
                                "
                              >
                                {{ deliveryDetail?.rental_barcode }}
                              </v-chip>
                            </template>
                            <span>Converted from rental</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Delivery Date
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <span style="color: #24326d !important">
                            {{ formatDate(deliveryDetail?.started_at) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Delivery Time
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <span style="color: #24326d !important">
                            {{
                              formatDateTimeStart(deliveryDetail?.started_at)
                            }}
                            -
                            {{ formatetimedata(deliveryDetail?.finished_at) }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Payment Type
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label
                            class="m-0"
                            v-if="delivery && deliveryDetail?.payment_type"
                          >
                            <template
                              v-if="
                                deliveryDetail?.payment_type ==
                                'cash-on-delivery'
                              "
                            >
                              <v-chip
                                outlined
                                label
                                style="height: 26px"
                                class="mr-2"
                                color="green white--text"
                              >
                                Cash On Delivery
                              </v-chip>
                            </template>
                            <template
                              v-if="deliveryDetail?.payment_type == 'prepaid'"
                            >
                              <v-chip
                                outlined
                                style="height: 26px"
                                class="mr-2"
                                color="cyan white--text"
                              >
                                Prepaid
                              </v-chip></template
                            >
                            <template
                              v-if="deliveryDetail?.payment_type == 'postpaid'"
                            >
                              <v-chip
                                outlined
                                style="height: 26px"
                                class="mr-2"
                                color="red white--text"
                              >
                                Postpaid
                              </v-chip></template
                            >
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Payment Mode
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label
                            class="m-0"
                            v-if="
                              delivery &&
                              deliveryDetail?.payment_mode_relation?.text
                            "
                          >
                            <v-chip
                              color="red darken-4"
                              label
                              small
                              outlined
                              text-color=""
                            >
                              {{
                                deliveryDetail?.payment_mode_relation?.text
                              }}</v-chip
                            >
                          </label>
                          <em v-else class="text-muted"> no payment mode </em>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="top"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Reference #
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label
                            class="m-0 text-capitalize"
                            v-if="delivery && deliveryDetail?.reference"
                            >{{ deliveryDetail?.reference }}</label
                          >
                          <em v-else class="text-muted"> no refernce </em>
                        </td>
                      </tr>
                      <!--   <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Old Delivery
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label
                class="m-0 text-capitalize"
                v-if="delivery && deliveryDetail?.old_do"
                >{{ deliveryDetail?.old_do }}</label
              >
              <em v-else class="text-muted"> no old delivery </em>
            </td>
          </tr> -->
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Priority
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <Priority
                            v-if="delivery && deliveryDetail?.priority"
                            class="my-1 ml-n2"
                            :priority="deliveryDetail?.priority"
                            small
                          ></Priority>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0">
            <span
              class="color-custom-blue font-weight-700 font-size-20 text-truncate"
              style=""
            >
              <template v-if="deliveryDetail && deliveryDetail.title"></template
              >{{ deliveryDetail.title }}
            </span>
            <v-layout class="my-4">
              <v-row>
                <v-col md="12">
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4 h-100"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                      >
                        Information</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <!--  <v-flex md12> 
                              
                          </v-flex> -->
                      <v-row class="pl-1">
                        <v-col
                          :md="deliveryDetail?.type == 'transfer' ? '3' : '4'"
                          class="bgfrom"
                        >
                          <v-layout class="align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading">
                                <template
                                  v-if="deliveryDetail?.type == 'transfer'"
                                >
                                  From </template
                                >Billing Location
                                <template
                                  v-if="
                                    deliveryDetail?.billing_relation
                                      ?.withcompany == 1
                                  "
                                >
                                  <v-chip
                                    label
                                    small
                                    class="ml-1"
                                    color="red"
                                    outlined
                                    style="min-height: 24px; font-size: 13px"
                                  >
                                    A&G
                                  </v-chip></template
                                >
                                <template
                                  v-if="
                                    deliveryDetail?.select_billing_type ==
                                      'from_customer_billing' &&
                                    deliveryDetail?.type == 'transfer'
                                  "
                                >
                                  <v-chip
                                    label
                                    small
                                    class="ml-1 text-white"
                                    color="green"
                                    style="min-height: 24px; font-size: 13px"
                                  >
                                    Billing
                                  </v-chip></template
                                >
                              </label></v-flex
                            >
                          </v-layout>
                          <template v-if="deliveryDetail?.billing_relation?.id">
                            <span
                              v-if="
                                deliveryDetail?.billing_relation &&
                                deliveryDetail?.billing_relation
                                  .property_address
                              "
                            >
                              <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                            >
                            <p
                              class="text-capitalize"
                              v-if="
                                deliveryDetail?.billing_relation &&
                                deliveryDetail?.billing_relation
                                  .property_address
                              "
                            >
                              <template
                                v-if="
                                  deliveryDetail?.billing_relation
                                    ?.property_name
                                "
                              >
                                {{
                                  deliveryDetail?.billing_relation
                                    ?.property_name
                                }}<br />
                              </template>
                              {{ deliveryDetail?.billing_relation.street_1 }},
                              <br
                                v-if="
                                  deliveryDetail?.billing_relation?.street_2 ||
                                  deliveryDetail?.billing_relation?.unit_no
                                "
                              />
                              <template
                                v-if="
                                  deliveryDetail?.billing_relation?.street_2
                                "
                              >
                                {{
                                  deliveryDetail?.billing_relation?.street_2
                                }},
                              </template>
                              <template
                                v-if="deliveryDetail?.billing_relation?.unit_no"
                              >
                                {{ deliveryDetail?.billing_relation?.unit_no }},
                              </template>
                              <br />
                              {{ deliveryDetail?.billing_relation.country }}
                              <template
                                v-if="
                                  deliveryDetail?.billing_relation &&
                                  deliveryDetail?.billing_relation.zip_code !=
                                    '000000'
                                "
                              >
                                ,
                                {{ deliveryDetail?.billing_relation.zip_code }}
                              </template>
                            </p>
                          </template>

                          <v-layout class="mt-3 align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading"
                                ><template
                                  v-if="deliveryDetail?.type == 'transfer'"
                                >
                                  From
                                </template>
                                Contact Details</label
                              ></v-flex
                            >
                          </v-layout>
                          <template
                            v-if="
                              deliveryDetail?.billing_contact_person_relation
                                ?.id
                            "
                          >
                            <p class="mb-0">
                              {{
                                deliveryDetail?.billing_contact_person_relation
                                  ?.display_name
                              }}
                            </p>
                            <p
                              class="mb-0"
                              v-if="
                                deliveryDetail?.billing_contact_person_relation
                                  ?.primary_phone
                              "
                            >
                              {{
                                deliveryDetail?.billing_contact_person_relation
                                  ?.primary_phone
                              }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no phone number</p>
                            </em>
                            <p
                              class="mb-0"
                              v-if="
                                deliveryDetail?.billing_contact_person_relation
                                  ?.primary_email
                              "
                            >
                              {{
                                deliveryDetail?.billing_contact_person_relation
                                  ?.primary_email
                              }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no email</p>
                            </em>
                          </template>
                        </v-col>
                        <v-col
                          :md="deliveryDetail?.type == 'transfer' ? '3' : '4'"
                          class="bgfrom"
                          :class="
                            deliveryDetail?.type == 'transfer' ? 'roborder' : ''
                          "
                        >
                          <v-layout class="align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading"
                                ><template
                                  v-if="deliveryDetail?.type == 'transfer'"
                                >
                                  From
                                </template>
                                {{ getSiteAddressTitle() }}</label
                              ></v-flex
                            >
                          </v-layout>

                          <template
                            v-if="deliveryDetail?.property_relation?.id"
                          >
                            <span
                              v-if="
                                deliveryDetail?.property_relation &&
                                deliveryDetail?.property_relation
                                  .property_address
                              "
                            >
                              <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                            >
                            <p
                              class="mr-2 text-capitalize"
                              v-if="
                                deliveryDetail?.property_relation &&
                                deliveryDetail?.property_relation
                                  .property_address
                              "
                            >
                              <template
                                v-if="
                                  deliveryDetail?.property_relation
                                    ?.property_name
                                "
                              >
                                {{
                                  deliveryDetail?.property_relation
                                    ?.property_name
                                }}<br />
                              </template>
                              {{ deliveryDetail?.property_relation.street_1 }},
                              <br
                                v-if="
                                  deliveryDetail?.property_relation.street_2 ||
                                  deliveryDetail?.property_relation.unit_no
                                "
                              />
                              <template
                                v-if="
                                  deliveryDetail?.property_relation.street_2
                                "
                              >
                                {{
                                  deliveryDetail?.property_relation.street_2
                                }},
                              </template>
                              <template
                                v-if="deliveryDetail?.property_relation.unit_no"
                              >
                                {{ deliveryDetail?.property_relation.unit_no }},
                              </template>
                              <br />
                              {{ deliveryDetail?.property_relation.country }}
                              <template
                                v-if="
                                  deliveryDetail?.property_relation &&
                                  deliveryDetail?.property_relation.zip_code !=
                                    '000000'
                                "
                              >
                                ,
                                {{ deliveryDetail?.property_relation.zip_code }}
                              </template>
                            </p>
                          </template>

                          <v-layout class="mt-3 align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading"
                                ><template
                                  v-if="deliveryDetail?.type == 'transfer'"
                                >
                                  From
                                </template>
                                Contact Details</label
                              ></v-flex
                            >
                          </v-layout>

                          <template
                            v-if="
                              deliveryDetail?.property_contact_person_relation
                                ?.id
                            "
                          >
                            <p class="mb-0">
                              {{
                                deliveryDetail?.property_contact_person_relation
                                  ?.display_name
                              }}
                            </p>
                            <p
                              class="mb-0"
                              v-if="
                                deliveryDetail?.property_contact_person_relation
                                  ?.primary_phone
                              "
                            >
                              {{
                                deliveryDetail?.property_contact_person_relation
                                  ?.primary_phone
                              }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no phone number</p>
                            </em>
                            <p
                              class="mb-0"
                              v-if="
                                deliveryDetail?.property_contact_person_relation
                                  ?.primary_email
                              "
                            >
                              {{
                                deliveryDetail?.property_contact_person_relation
                                  ?.primary_email
                              }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no email</p>
                            </em>
                          </template>
                        </v-col>
                        <v-col
                          :md="deliveryDetail?.type == 'transfer' ? '3' : '4'"
                          class="bgfrom"
                          v-if="deliveryDetail?.type != 'transfer'"
                        >
                          <v-layout class="align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading"
                                >{{ getPickupAddressTitle() }}
                                <template v-if="deliveryDetail?.picAddress?.id">
                                  <v-chip
                                    label
                                    small
                                    class="ml-1"
                                    color="red"
                                    outlined
                                    style="min-height: 24px; font-size: 13px"
                                  >
                                    A&G
                                  </v-chip></template
                                >
                              </label></v-flex
                            >
                          </v-layout>

                          <template v-if="deliveryDetail?.picAddress?.id">
                            <span v-if="deliveryDetail?.picAddress">
                              <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                            >
                            <p class="mr-2 text-capitalize">
                              <template
                                v-if="deliveryDetail?.picAddress?.property_name"
                              >
                                {{ deliveryDetail?.picAddress?.property_name
                                }}<br />
                              </template>
                              {{ deliveryDetail?.picAddress.street_1 }},
                              <br
                                v-if="
                                  deliveryDetail?.picAddress.street_2 ||
                                  deliveryDetail?.picAddress.unit_no
                                "
                              />
                              <template
                                v-if="deliveryDetail?.picAddress.street_2"
                              >
                                {{ deliveryDetail?.picAddress.street_2 }},
                              </template>
                              <template
                                v-if="deliveryDetail?.picAddress.unit_no"
                              >
                                {{ deliveryDetail?.picAddress.unit_no }},
                              </template>
                              <br />
                              {{ deliveryDetail?.picAddress.country }}
                              <template
                                v-if="
                                  deliveryDetail?.picAddress &&
                                  deliveryDetail?.picAddress.zip_code !=
                                    '000000'
                                "
                              >
                                , {{ deliveryDetail?.picAddress.zip_code }}
                              </template>
                            </p>
                          </template>

                          <v-layout class="mt-3 align-center">
                            <v-flex md12
                              ><label class="my-0 address-heading"
                                >Contact Details</label
                              ></v-flex
                            >
                          </v-layout>

                          <template v-if="deliveryDetail?.picAddressuser?.id">
                            <p class="mb-0">
                              {{ deliveryDetail?.picAddressuser?.display_name }}
                            </p>
                            <p
                              class="mb-0"
                              v-if="
                                deliveryDetail?.picAddressuser?.phone_number
                              "
                            >
                              {{ deliveryDetail?.picAddressuser?.phone_number }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no phone number</p>
                            </em>
                            <p
                              class="mb-0"
                              v-if="deliveryDetail?.picAddressuser?.user_email"
                            >
                              {{ deliveryDetail?.picAddressuser?.user_email }}
                            </p>
                            <em v-else class="text-muted">
                              <p class="mb-0">no email</p>
                            </em>
                          </template>
                        </v-col>
                        <template v-if="deliveryDetail?.type == 'transfer'">
                          <v-col
                            :md="deliveryDetail?.type == 'transfer' ? '3' : '4'"
                            class="bgto"
                          >
                            <v-layout class="align-center">
                              <v-flex md12
                                ><label class="my-0 address-heading"
                                  ><template
                                    v-if="deliveryDetail?.type == 'transfer'"
                                  >
                                    To </template
                                  >Billing Location</label
                                >
                                <template
                                  v-if="
                                    deliveryDetail?.to_billing_relation
                                      ?.withcompany == 1
                                  "
                                >
                                  <v-chip
                                    label
                                    small
                                    class="ml-1"
                                    color="red"
                                    outlined
                                    style="min-height: 24px; font-size: 13px"
                                  >
                                    A&G
                                  </v-chip></template
                                >
                                <template
                                  v-if="
                                    deliveryDetail?.select_billing_type ==
                                      'to_customer_billing' &&
                                    deliveryDetail?.type == 'transfer'
                                  "
                                >
                                  <v-chip
                                    label
                                    small
                                    class="ml-1 text-white"
                                    color="green"
                                    style="min-height: 24px; font-size: 13px"
                                  >
                                    Billing
                                  </v-chip></template
                                >
                              </v-flex>
                            </v-layout>
                            <template
                              v-if="deliveryDetail?.to_billing_relation?.id"
                            >
                              <span
                                v-if="
                                  deliveryDetail?.to_billing_relation &&
                                  deliveryDetail?.to_billing_relation
                                    .property_address
                                "
                              >
                                <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                              >
                              <p
                                class="text-capitalize"
                                v-if="
                                  deliveryDetail?.to_billing_relation &&
                                  deliveryDetail?.to_billing_relation
                                    .property_address
                                "
                              >
                                <template
                                  v-if="
                                    deliveryDetail?.to_billing_relation
                                      ?.property_name
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_billing_relation
                                      ?.property_name
                                  }}<br />
                                </template>
                                {{
                                  deliveryDetail?.to_billing_relation?.street_1
                                }},
                                <br
                                  v-if="
                                    deliveryDetail?.to_billing_relation
                                      ?.street_2 ||
                                    deliveryDetail?.to_billing_relation?.unit_no
                                  "
                                />
                                <template
                                  v-if="
                                    deliveryDetail?.to_billing_relation
                                      ?.street_2
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_billing_relation
                                      .street_2
                                  }},
                                </template>
                                <template
                                  v-if="
                                    deliveryDetail?.to_billing_relation?.unit_no
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_billing_relation
                                      ?.unit_no
                                  }},
                                </template>
                                <br />
                                {{
                                  deliveryDetail?.to_billing_relation?.country
                                }}
                                <template
                                  v-if="
                                    deliveryDetail?.to_billing_relation &&
                                    deliveryDetail?.to_billing_relation
                                      .zip_code != '000000'
                                  "
                                >
                                  ,
                                  {{
                                    deliveryDetail?.to_billing_relation.zip_code
                                  }}
                                </template>
                              </p>
                            </template>

                            <v-layout class="mt-3 align-center">
                              <v-flex md12
                                ><label class="my-0 address-heading"
                                  ><template
                                    v-if="deliveryDetail?.type == 'transfer'"
                                  >
                                    To
                                  </template>
                                  Contact Details</label
                                ></v-flex
                              >
                            </v-layout>
                            <template
                              v-if="
                                deliveryDetail
                                  ?.to_billing_contact_person_relation?.id
                              "
                            >
                              <p class="mb-0">
                                {{
                                  deliveryDetail
                                    ?.to_billing_contact_person_relation
                                    .display_name
                                }}
                              </p>
                              <p class="mb-0">
                                {{
                                  deliveryDetail
                                    ?.to_billing_contact_person_relation
                                    .primary_phone
                                }}
                              </p>
                              <p class="mb-0">
                                {{
                                  deliveryDetail
                                    ?.to_billing_contact_person_relation
                                    .primary_email
                                }}
                              </p>
                            </template>
                          </v-col>
                          <v-col
                            :md="deliveryDetail?.type == 'transfer' ? '3' : '4'"
                            class="bgto"
                          >
                            <v-layout class="align-center">
                              <v-flex md12
                                ><label class="my-0 address-heading"
                                  ><template
                                    v-if="deliveryDetail?.type == 'transfer'"
                                  >
                                    To
                                  </template>
                                  Dropoff Address</label
                                ></v-flex
                              >
                            </v-layout>

                            <template
                              v-if="deliveryDetail?.to_property_relation?.id"
                            >
                              <span
                                v-if="
                                  deliveryDetail?.to_property_relation &&
                                  deliveryDetail?.to_property_relation
                                    .property_address
                                "
                              >
                                <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                              >
                              <p
                                class="mr-2 text-capitalize"
                                v-if="
                                  deliveryDetail?.to_property_relation &&
                                  deliveryDetail?.to_property_relation
                                    .property_address
                                "
                              >
                                <template
                                  v-if="
                                    deliveryDetail?.to_property_relation
                                      ?.property_name
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_property_relation
                                      ?.property_name
                                  }}<br />
                                </template>
                                {{
                                  deliveryDetail?.to_property_relation.street_1
                                }},
                                <br
                                  v-if="
                                    deliveryDetail?.to_property_relation
                                      .street_2 ||
                                    deliveryDetail?.to_property_relation.unit_no
                                  "
                                />
                                <template
                                  v-if="
                                    deliveryDetail?.to_property_relation
                                      .street_2
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_property_relation
                                      .street_2
                                  }},
                                </template>
                                <template
                                  v-if="
                                    deliveryDetail?.to_property_relation.unit_no
                                  "
                                >
                                  {{
                                    deliveryDetail?.to_property_relation
                                      .unit_no
                                  }},
                                </template>
                                <br />
                                {{
                                  deliveryDetail?.to_property_relation.country
                                }}
                                <template
                                  v-if="
                                    deliveryDetail?.to_property_relation &&
                                    deliveryDetail?.to_property_relation
                                      .zip_code != '000000'
                                  "
                                >
                                  ,
                                  {{
                                    deliveryDetail?.to_property_relation
                                      .zip_code
                                  }}
                                </template>
                              </p>
                            </template>

                            <v-layout class="mt-3 align-center">
                              <v-flex md12
                                ><label class="my-0 address-heading"
                                  ><template
                                    v-if="deliveryDetail?.type == 'transfer'"
                                  >
                                    To
                                  </template>
                                  Contact Details</label
                                ></v-flex
                              >
                            </v-layout>

                            <template
                              v-if="
                                deliveryDetail
                                  ?.to_property_contact_person_relation?.id
                              "
                            >
                              <p class="mb-0">
                                {{
                                  deliveryDetail
                                    ?.to_property_contact_person_relation
                                    .display_name
                                }}
                              </p>

                              <p
                                class="mb-0"
                                v-if="
                                  deliveryDetail
                                    ?.to_property_contact_person_relation
                                    .primary_phone
                                "
                              >
                                {{
                                  deliveryDetail
                                    ?.to_property_contact_person_relation
                                    .primary_phone
                                }}
                              </p>
                              <em v-else class="text-muted">
                                <p class="mb-0">no phone number</p>
                              </em>
                              <p
                                class="mb-0"
                                v-if="
                                  deliveryDetail
                                    ?.to_property_contact_person_relation
                                    .primary_email
                                "
                              >
                                {{
                                  deliveryDetail
                                    ?.to_property_contact_person_relation
                                    .primary_email
                                }}
                              </p>
                              <em v-else class="text-muted">
                                <p class="mb-0">no email</p>
                              </em>
                            </template>
                          </v-col>
                        </template>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Priority from "@/view/pages/partials/Priority.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import { GET } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  props: {
    deliveryDialog: {
      type: Boolean,
      default: false,
    },
    isCalendar: {
      type: Boolean,
      default: false,
    },
    delivery: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    delivery: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.loadContent();
        }
      },
    },
  },
  data() {
    return {
      deliveryDetail: {},
      pageLoading: false,
    };
  },
  methods: {
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "delivery/v1/" + _this.delivery,
        })
        .then(({ data }) => {
          _this.deliveryDetail = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getIcon() {
      if (this.deliveryDetail?.type == "incoming") {
        return "mdi-transfer-down";
      } else if (this.deliveryDetail?.type == "outgoing") {
        return "mdi-transfer-up";
      } else if (this.deliveryDetail?.type == "transfer") {
        return "mdi-transfer";
      }
    },

    getColor() {
      if (this.deliveryDetail?.type == "incoming") {
        return "incoming-color white--text";
      } else if (this.deliveryDetail?.type == "outgoing") {
        return "outgoing-color white--text";
      } else if (this.deliveryDetail?.type == "transfer") {
        return "transfer-color";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    getSiteAddressTitle() {
      if (this.deliveryDetail?.type == "incoming") {
        return "Pickup Address";
      } else if (this.deliveryDetail?.type == "outgoing") {
        return "Dropoff Address";
      } else if (this.deliveryDetail?.type == "transfer") {
        return "Pickup Address";
      }
    },
    getPickupAddressTitle() {
      if (this.deliveryDetail?.type == "incoming") {
        return "Dropoff Address";
      } else if (this.deliveryDetail?.type == "outgoing") {
        return "Pickup Address";
      } else if (this.deliveryDetail?.type == "transfer") {
        return "Dropoff Address";
      }
    },
  },
  components: {
    Dialog,
    Priority,
    CustomStatus,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },

    getAppointmentText() {
      if (this.lodash.isEmpty(this.deliveryDetail) === false) {
        let date = this.formatDate(this.deliveryDetail.started_at);
        let startTime = moment(this.deliveryDetail.started_at).format(
          "hh:mm A"
        );
        let endTime = moment(this.deliveryDetail.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
    getScheduleType() {
      if (this.deliveryDetail) {
        if (this.deliveryDetail.type === 1) {
          return "One-Off Job";
        }
        if (this.deliveryDetail.type === 2) {
          return "Recurring Job";
        }
      }
      return null;
    },
  },
};
</script>
